.message {
  width: fit-content;
  padding: 0.5rem;
  background-color: #f3f3f3;
  word-break: break-word;
}

.containerMessage {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  align-items: end;
  gap: 0.5rem;
}

.chat {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: inherit;
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: scroll;
  overflow-x: hidden;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background: #f3f3f3;
  gap: 0.5rem;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.containerButtons {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.containerBtnLike {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
